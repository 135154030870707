<template>
    <v-layout column="" pr-3 mr-2 mt-1>
        <v-dialog v-model="Advertising_dialog" max-width="850" max-height="400" v-if="Advertising_dialog" persistent>



            <div>
                <v-form ref="form" v-model="valid">
                    <v-card pa-4>

                        <v-card-title class="headline grey lighten-2" primary-title
                            style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">




                            <span v-if="editedIndex>=0">تعديل اعلان</span>
                            <span v-else>اضافه اعلان</span>
                        </v-card-title>


                        <v-container>


                            <v-flex pa-3 xs12 v-if="editedIndex>=0">
                                <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                    @change="type_advertising_method()" :items="advertising_type" item-value="id"
                                    disabled item-text="name" v-model="type_advertising" placeholder="نوع الاعلان"
                                    required>
                                </v-select>
                            </v-flex>

                            <v-flex pa-3 xs12 v-else>
                                <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                    @change="type_advertising_method()" :items="advertising_type" item-value="id"
                                    item-text="name" v-model="type_advertising" placeholder="نوع الاعلان" required>
                                </v-select>
                            </v-flex>



                            <v-flex pa-3 xs12 >

                                <div v-if="editedIndex>=0">
                                    <v-select v-if="type_advertising==2"  background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                        :items="items" item-value="id" item-text="item_name"
                                        v-model="editedItem.item.id" disabled placeholder="اسم الخدمه" required>
                                    </v-select>
                                </div>

                                <div  v-if="type_advertising==2"  >
                                    <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                        :items="items" item-value="id" item-text="item_name"
                                        v-model="editedItem.item.id" placeholder="اسم الخدمه" required>
                                    </v-select>
                                </div>

                            </v-flex>











                            <v-flex pa-3 xs12  v-if="editedIndex>=0 && editedItem.is_admin_approved==1" >
                                <div>نوع الاشتراك</div>
                                     <!-- <template v-slot:label> -->

                                                <v-layout row wrap>
                                                    <v-flex xs3 md2 sm2 pr-5>
                                                        <div style="text-align:right;"> <strong
                                                                style="color:green">{{editedItem.advertisingType.advertising_type_name}}
                                                            </strong>
                                                        </div>
                                                    </v-flex>

                                                    <v-flex pr-1 xs5 md2 sm2>
                                                        <div><span style="font-weight:bold"> عدد الايام
                                                            </span> <strong style="color:blue;padding-right:10px">
                                                                {{editedItem.advertisingType.days_number}} </strong> </div>
                                                    </v-flex>

                                                    <v-flex pr-3 xs5 md3 sm3>
                                                        <div><span style="font-weight:bold"> عدد ثواني الظهور
                                                            </span> <strong style="color:blue;padding-right:10px">
                                                                {{editedItem.advertisingType.secondsـappearing_number}} </strong> </div>
                                                    </v-flex>

                                                   

                                                    <v-flex xs4 sm3 md3>
                                                        <div><strong style="color:red;padding-right:10px;">السعر
                                                            </strong>
                                                            {{editedItem.advertisingType.advertising_price}} دينار</div>

                                                    </v-flex>

                                                </v-layout>



                                            <!-- </template> -->
                            </v-flex>


                          <v-flex xs12 v-else>
                                <v-radio-group required mandatory :rules="PackagRules" v-model="editedItem.advertising_type_id" >
                                <div>نوع الاشتراك</div>
                                <v-flex pt-2 xs12 v-for="item in  advertising_types" :key="item.id">
                                    <v-card style="padding:10px;width:100%">

                                        <v-radio label="" :value="item.id">


                                            <template v-slot:label>

                                                <v-layout row wrap>
                                                    <v-flex xs3 md2 sm2 pr-5>
                                                        <div style="text-align:right;"> <strong
                                                                style="color:green">{{item.advertising_type_name}}
                                                            </strong>
                                                        </div>
                                                    </v-flex>

                                                    <v-flex pr-1 xs5 md2 sm2>
                                                        <div><span style="font-weight:bold"> عدد الايام
                                                            </span> <strong style="color:blue;padding-right:10px">
                                                                {{item.days_number}} </strong> </div>
                                                    </v-flex>

                                                    <v-flex pr-3 xs5 md3 sm3>
                                                        <div><span style="font-weight:bold"> عدد ثواني الظهور
                                                            </span> <strong style="color:blue;padding-right:10px">
                                                                {{item.secondsـappearing_number}} </strong> </div>
                                                    </v-flex>

                                                   

                                                    <v-flex xs4 sm3 md3>
                                                        <div><strong style="color:red;padding-right:10px;">السعر
                                                            </strong>
                                                            {{item.advertising_price}} دينار</div>

                                                    </v-flex>

                                                </v-layout>



                                            </template>
                                        </v-radio>
                                    </v-card>
                                </v-flex>
                            </v-radio-group>
                          </v-flex>



<br>
                            <v-layout>
                                <v-flex xs12>

                                    <v-textarea filled name="input-7-4" required v-model="editedItem.adv_description"
                                        :rules="descriptRules" label="وصف الاعلان" height="200">
                                    </v-textarea>

                                </v-flex>
                            </v-layout>







                            <v-row justify="center" class="mb-6">


                                <v-card height="200" width="200" class="card_img">

                                    <div v-if="editedItem.image.length !==0">

                                        <v-img :src="imgs_url" height="200" width="200" class="card_img">
                                            <v-btn icon="" style="padding-right: 24px;position:relative;left: 46px;"
                                                @click="delete_img(editedItem.image[0].id,0)">
                                                <v-icon color="#fff">fas fa-window-close</v-icon>
                                            </v-btn>
                                        </v-img>
                                    </div>

                                    <div v-else>
                                        <!-- <v-img :src="editedItem.image[0].image_url" height="200" width="200" class="card_img"  > -->
                                        <v-btn icon @click='pickFile()'>
                                            <v-icon style="margin:0px" size="40">fas fa-plus-square</v-icon>
                                        </v-btn>
                                    </div>



                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                        @change="onFilePicked">
                                </v-card>

                            </v-row>


                            <v-container fluid>


                                <p>
                                    حاله الاعلان :

                                    <v-radio-group v-model="editedItem.active" :mandatory="false" :rules="active">
                                        <v-radio label="تفعيل" :value="1"></v-radio>
                                        <v-radio label="ايقاف" :value="0"></v-radio>
                                    </v-radio-group>

                                </p>


                                <p  v-if="editedIndex>=0">
                                    حاله النشر :

                                    <v-chip v-if="editedItem.is_admin_approved==1" class="ma-2" color="green"
                                        text-color="white">
                                        <v-avatar right>
                                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                                        </v-avatar>
                                        تمت الموافقه
                                    </v-chip>


                                    <v-chip v-else class="ma-2" color="#f07821" text-color="white">
                                        <v-avatar right>
                                            <v-icon>fas fa-clock</v-icon>
                                        </v-avatar>
                                        معلق
                                    </v-chip>



                                </p>


                                <p  v-if="editedIndex>=0 && editedItem.is_admin_approved==1" style="font-weight:bold">
                                    عدد الايام المتبقيه : {{editedItem.remaining_days_num}}

                                </p>




                            </v-container>

                            <br>
                            <br>

                            <v-layout row wrap pr-5>
                                <v-btn @click="submitـadvertisings()" color="success" style="margin-left:10px"
                                    width="150px">
                                    <span v-show="!loading">حفظ</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>
                                </v-btn>
                                <v-btn @click="close_offer()" color="red" dark="" width="150px"> خروج</v-btn>
                            </v-layout>
                        </v-container>


                    </v-card>
                </v-form>
            </div>
        </v-dialog>

        <v-row>

            <v-data-table :headers="headers" :items="advertising" :page.sync="page" @page-count="pageCount = $event"
                sort-by="calories" class="elevation-1 request_table" style="width:96%" items-per-page="15"
                hide-default-footer="" :loading="loading" loading-text="جاري تحميل البيانات">

                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>الاعلانات</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-row justify="end" style="margin-top:20px;">
                            <div style="width:auto">

                                <v-btn color="primary" dark class="mb-2" @click="Advertising_dialog=true">
                                    <span style="margin-left:5px;font-family:'cairo_Semi'">اضافه اعلان جديد</span>
                                    <v-icon size="15">fas fa-plus</v-icon>

                                </v-btn>



                            </div>



                        </v-row>

                    </v-toolbar>
                </template>
                <template v-slot:bottom>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </template>



                <template v-slot:item.action="{ item }">
                    <v-btn text icon @click="editItem(item)">
                        <i class="fas fa-edit fa-lg"></i>
                    </v-btn>

                    <v-btn class="mx-2" small icon="" @click="deleteItem(item)" dark>
                        <v-icon color="red">fas fa-trash</v-icon>
                    </v-btn>


                </template>

                <template v-slot:item.type="{ item }">
                    <span v-if="item.item.owner_barnd_name">
                        اعلان لجميع الخدمات
                    </span>

                    <span v-else>
                        اعلان خدمه
                    </span>




                </template>


                <template v-slot:item.name="{ item }">
                    <span v-if="item.item.owner_barnd_name">
                        لجميع المراكز
                    </span>

                    <span v-else>
                        {{item.item.item_name}}

                    </span>




                </template>


                <template v-slot:item.status="{ item }">


                    <v-chip class="res_pinding_chip" dark="" style="color:#fff !important" color="green"
                        v-if="item.active==1  && item.is_admin_approved==1">
                        <span style="color:#fff !important">
                            فعال
                        </span>

                    </v-chip>

                    <v-chip v-else-if="item.active==0  && item.is_admin_approved==1" class="res_pinding_chip" dark=""
                        style="color:#fff" color="red">

                        <span style="color:#fff !important">
                            متوقف
                        </span>

                    </v-chip>


                    <v-chip v-else-if="item.active==1  && item.is_admin_approved==0" class="res_pinding_chip" dark=""
                        style="color:#fff" color="#f07821">

                        <span style="color:#fff !important">
                            معلق
                        </span>

                    </v-chip>




                </template>








            </v-data-table>

        </v-row>
        <v-row>
            <v-pagination v-model="page" @change="getmore" prev-icon="mdi-menu-right" next-icon="mdi-menu-left"
                circle="" :length="pageCount"></v-pagination>
        </v-row>
    </v-layout>

</template>



<script>
    const axios = require('axios');

    export default {
        props: {
            'item_ID': Number,
            'Advertising_info': Object

        },
        data: () => ({
            type_advertising: '',
            item_id_offer: '',
            submit_name: '',
            imgs_url: '',
            img_name: '',
            advertising: [],
            //img_url: 'http://109.224.27.9:81',
            item_id: '',
            name: '',
            images: null,
                PackagRules: [
                (v) => !!v || 'يجب اختيار حزمه',

            ],

            advertising_type: [{
                    id: 1,
                    name: 'اعلان لجميع الخدمات'
                },
                {
                    id: 2,
                    name: 'اعلان لخدمه'
                }


            ],
            headers: [{
                    text: '#',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },

                {
                    text: 'نوع الاعلان',
                    align: 'center',
                    sortable: false,
                    value: 'type',
                },

                {
                    text: 'اسم الخدمه',
                    align: 'center',
                    sortable: false,
                    value: 'name',
                },



                {
                    text: 'اسم الاشتراك',
                    align: 'center',
                    sortable: false,
                    value: 'advertisingType.advertising_type_name',
                },

                {
                    text: 'عدد الايام المتبقيه',
                    align: 'center',
                    sortable: false,
                    value: 'remaining_days_num',
                },





                {
                    text: 'الحاله',
                    align: 'center',
                    sortable: false,
                    value: 'status',
                },







                {
                    text: 'تاريخ الاضافة',
                    value: 'created_at',
                    sortable: false
                },


                {
                    text: ' ',
                    value: 'action',
                    align: 'center',
                },

            ],
            itemId: '',
            token: '',
            map_dialog: false,
            Advertising_dialog: false,
            modal2: false,
            items: '',
            editedItem: {
                id: '',
                owner_id: '',
                item_id: '',
                advertising_type_id: '',
                adv_description: '',
                advertisingType:{
                    advertising_price:'',

                    advertising_type_name:'',
                    days_number:'',
                    secondsـappearing_number:''

                },
                active: '',
                item: {
                    id: ''
                },
                image: [


                ]


            },

            editedIndex: -1,


            valid: false,
            deduction: '',
            offer_info_id: '',
            img_cliced: -1,
            day_to_open: [],
            active: [
                v => !isNaN(v) || 'هذا الحقل مطلوب  ',
            ],
            nameRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
            ],
            numberRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            numberRul1: [

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            descriptRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                //  v => v.length >= 20 || 'يجب ان يكون الوصف اكثر من ٢٠ حرف ',
            ],
            loading: false,



        }),



        methods: {


            editItem(item) {
                this.Advertising_dialog = true
                this.editedItem = Object.assign({}, item)
                this.imgs_url = this.Url + '/images/' + this.editedItem.image[0].image_url;

                if (this.editedItem.item.owner_barnd_name) {
                    this.type_advertising = 1;



                } else {
                    this.type_advertising = 2;
                }
                this.editedIndex = this.advertising.indexOf(item)



            },
            type_advertising_method() {
                if (this.type_advertising == 1) {

                    this.editedItem.owner_id = this.$cookies.get('owner_info').id;
                } else if (this.type_advertising == 2) {

                    this.getItems();


                }


            },
            deleteItem(item) {

                const Swal = require('sweetalert2')



                Swal.fire({
                    title: "هل انت متاكد من الحذف ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'

                }).then((result) => {
                    if (result.value) {
                        var url = "/v2/Advertising/owner/delete/" + item.id;
                        this.$http({
                            method: 'get',
                            url: url,
                            headers: {

                            }

                        }).then(response => {
                            response



                        }).catch(error => {
                            error,
                            this.$swal('خطاء', "خطاء بالاتصال", 'error')
                        }).finally(d => {
                            d,
                            Swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )
                            this.getaAdvertising();
                        });



                    }
                })




            },



            getAllAdvTypes() {
                var url = "v2/owner/getAllAdvTypes";
                this.$http.get(url).then(response => {
                    this.advertising_types = response.data.data;
                })

            },

            getaAdvertising() {
                var url = "v2/Advertising/owner/getMyAdvs";
                this.$http.get(url).then(response => {
                    this.advertising = response.data.data;
                    console.log(this.advertising);
                    this.last_page = response.data.meta.last_page;
                    this.pageCount = response.data.meta.last_page;
                })

            },




            close_offer() {
                this.editedIndex = -1;
                this.editedItem = {
                        id: '',
                        owner_id: '',
                        item_id: '',
                        advertising_type_id: '',
                        adv_description: '',
                        active: '',
                        item: {
                            id: ''
                        },
                        image: [


                        ]


                    },
                    //this.editedItem={};
                    this.Advertising_dialog = false;

            },

            submitـadvertisings() {


                if (this.$refs.form.validate()) {

                    var img = '';
                    if (this.editedItem.image.length == 0) {
                        this.$swal('  خطاء', "    يجب اختيار صوره الاعلان ", 'error');
                    }

                    if (this.editedItem.image[0].image_url.length > 100) {
                        img = [this.editedItem.image[0].image_url];
                    } else {
                        img = [];

                    }

                    var data = {
                        item_id: this.editedItem.item.id,
                        owner_id: this.editedItem.owner_id,
                        advertising_type_id: this.editedItem.advertising_type_id,
                        adv_description: this.editedItem.adv_description,
                        active: this.editedItem.active,
                        images: img,

                    };

                    if (this.editedIndex > -1) {


                        var posturl = "/v2/Advertising/owner/updateAdv/" + this.editedItem.id;

                    } else {

                        posturl = "/v2/Advertising/owner/addAdv";
                    }






                    this.loading = true;
                    axios({
                            method: 'post',
                            url: posturl,
                            data: data,

                        })
                        .then(response => {


                            if (this.editedIndex > -1) {
                                this.$swal("تم تعديل الاعلان ", "", "success")



                            } else {
                                this.$swal("تم اضافه الاعلان", "", "success")


                            }
                            this.Advertising_dialog = false;
                            this.$refs.form.reset();
                            this.getaAdvertising();

                            if (response.data.success == true) {
                                this.$refs.form.reset()
                                this.dialog = false;


                            }
                            //



                        })

                        .catch(error => {
                                this.err_show = true;
                                if (error.response) {
                                    if (error.response.data.data == 'SubscriptionsPackages Expire') {
                                        this.$swal('', "حزمه الاشتراك الحاليه منتهيه يرجئ شراء حزمه جديده ", 'error')

                                    }


                                }
                            }

                        )
                        .finally(d => {
                            d,
                            this.offer_dialog = false;
                            this.loading = false;
                        });
                } else {


                    this.$swal('  خطاء', " يجب ملئ جميع الحقول   ", 'error');




                }

            },









            getItems() {

                //var url = "/v2/items/search?filter[owner.user_id]=" + this.$cookies.get('owner_info').id;
                var url = "/v2/items/owner/get?page=" + this.current_page;
                this.loading = true
                this.$http({
                    method: 'get',
                    url: url,
                    headers: {

                    }

                }).then(response => {
                    this.items = response.data.data;
                    this.last_page = response.data.meta.last_page;
                    this.pageCount = response.data.meta.last_page;


                }).catch(error => {

                    error


                }).finally(d => {
                    d,
                    this.loading = false;
                });

            },






            delete_img(img_id, index) {

                const Swal = require('sweetalert2');
                Swal.fire({
                    title: "هل انت متاكد من الحذف ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'

                }).then((result) => {
                    if (result.value) {
                        this.img_cliced = index;
                        // this.editedItem.image[0].image_url = '';
                        this.editedItem.image = [];
                        this.img_name = 'ghjk'

                        var url = "/v2/items/delete_image/" + img_id;
                        axios({
                            method: 'post',
                            url: url,
                            headers: {

                            }

                        }).then(response => {

                            response,

                            //not important
                            this.img_name = index;
                            this.editedItem.image[0].image_url = '';

                            Swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )

                        }).catch(error => {
                            error
                            this.$swal('خطاء', "خطاء بالاتصال", 'error')
                        }).finally(d => {
                            d,
                            this.getitems();
                        });
                    }
                })
            },
            cancelImg() {
                this.editedItem.image[0].image_url = '';


            },



            pickFile() {


                this.$refs.image.click()
            },

            onFilePicked(e) {

                const files = e.target.files
                if (files[0] !== undefined) {

                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    let self = this;
                    fr.addEventListener('load', () => {
                        self.editedItem.image = [{
                            image_url: '',
                        }];

                        self.editedItem.image[0].image_url = fr.result;
                        this.imgs_url = fr.result;
                    })

                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },






            close() {
                this.dialog = false
                this.editedIndex = -1
                this.editedItem = {};
                this.$emit("Advertising_dialog", false);
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },



        },


        created() {



        },
        mounted() {
            this.getaAdvertising();

            this.getItems();
            this.getAllAdvTypes();




        },
        components: {


        }
    }
</script>